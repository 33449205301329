import React from 'react'
import { Link } from 'react-router-dom';
import { FaWhatsappSquare } from "react-icons/fa";

function Whatsapp() {
  return (
    <div className='whatsapp'>  <h1>how can we help you? <Link style={{color:'rgb(45, 150, 45)', fontSize:'30px'}} to='https://wa.me/+2349114029469'> <FaWhatsappSquare /></Link></h1></div>
  )
}

export default Whatsapp;