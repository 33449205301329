import React from 'react'

function About() {
  return (
    <div>
      <p className='aboutheader' data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" >Books publications</p>
      <p className='aboutinfo'data-aos="fade-left"
     >
      magazines papers cards and boards plates roll up stand sav and  flex materials ACP aluminum composite panels solite backlit materials 
     </p>
     <p>
     </p>
    </div>
  )
}

export default About;