import React from 'react'

function Contact() {
  return (
    <div>
        <h1 className='services'  data-aos="zoom-in" data-aos-duration="8000"> books periodicals journals and podcast publishing 
</h1>
        <h1 className='services' data-aos="zoom-in" data-aos-duration="8000">screen printing 
</h1>
        <h1 className='services' data-aos="zoom-in" data-aos-duration="8000"> 
Offset printing
</h1>
        <h1 className='services' data-aos="zoom-in" data-aos-duration="8000" > large format printing </h1>
        <h1 className='services' data-aos="zoom-in" data-aos-duration="8000">digital printing and  direct imaging 
branding</h1>
        <h1 className='services' data-aos="zoom-in" data-aos-duration="8000"> gift branding wall branding 
laminating</h1>
    </div>
  )
}

export default Contact;