import React from 'react'
import imageone from './print247imageone.jpeg'
import imagetwo from './print247imagetwo.jpeg'
import imagethree from './print247imagethree.jpeg'
import imagefour from './print247imagefour.jpeg'
import imagefive from './print247imagefive.jpeg'
import imagesix from './print247imagesix.jpeg'
import one from './one.jpeg'
import two from './two.jpeg'
import  three from './three.jpeg'
import four from './four.jpeg'
import five from './five.jpeg'
import six from './six.jpeg'
import seven from './seven.jpeg'
import eight from './eight.jpeg'
import nine from './nine.jpeg'
import ten from './ten.jpeg'
import eleven from './eleven.jpeg'
import twelve from './twelve.jpeg'
import thirteen from './thirteen.jpeg'
import fourteen from './fourthteen.jpeg'
import fifthteen from './15.jpeg'
import sixteen from './16.jpeg'
import seventeen from './17.jpeg'
import eightteen from './18.jpeg'

import nineteen from './19.jpeg'
import twenty from './20.jpeg'
import Footer from '../two/Footer'

import { Link } from 'react-router-dom'



function Homepage() {
  return (
    <div>
        <h1  className='someworks'>Possibilities..</h1>
       
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imageone}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={twenty}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imagethree}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imagefour}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imagefive}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imagesix}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={one}/></div>
        <div className='imagetwo'data-aos="zoom-in-up" data-aos-duration="3000"><img src={two}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone'data-aos="zoom-in-up" data-aos-duration="3000"><img src={three}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={four}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up"data-aos-duration="3000"><img src={five}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={six}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={seven}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={eight}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={nine}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={ten}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={eleven}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={twelve}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={thirteen}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={fourteen}/></div>
        </div>

        <div className='imagesectionone'>
        <div className='imageone'data-aos="zoom-in-up" data-aos-duration="3000"><img src={fifthteen}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={sixteen}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={seventeen}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={eightteen}/></div>
        </div>
        <div className='imagesectionone'>
        <div className='imageone' data-aos="zoom-in-up" data-aos-duration="3000"><img src={nineteen}/></div>
        <div className='imagetwo' data-aos="zoom-in-up" data-aos-duration="3000"><img src={imagetwo}/></div>
        </div>
        <br></br>

    
<Footer/>
    </div>
  )
}

export default Homepage;