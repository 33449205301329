import React from 'react'

function Home() {
  return (
    <div className='studio'>
  
 <h1  data-aos="fade-right"   data-aos-duration="5000">Artist illustrator </h1>
 <h1 data-aos="fade-right"   data-aos-duration="5000">Copywriter </h1>
 <h1 data-aos="fade-right"   data-aos-duration="5000"> typist </h1>
 <h1 data-aos="fade-left"   data-aos-duration="5000">  desktop  </h1>
<h1 data-aos="fade-left"   data-aos-duration="5000">publisher</h1>
<h1 data-aos="fade-left"   data-aos-duration="5000">   plate maker</h1>
<h1 data-aos="fade-left"   data-aos-duration="5000"> block maker </h1>
    </div>
  )
}

export default Home ;