
import './App.css';
import {  Routes, Route } from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Error from './components/Error';
import Navbar from './components/Navbar';


import AOS from 'aos';
import 'aos/dist/aos.css';

import React,{useEffect} from "react"
import Homepage from './components/Homepage';
import Whatsapp from './Whatsapp';
import Contacttwo from './components/Contact.jsx';

function App() {
  
    useEffect(() => {
      AOS.init();
    }, [])
  return (
    <div className="App">
     <Navbar/>
    
     <Routes>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/studio' element={<Home/>} />
      <Route path='/about' element={<About/>}/>
      <Route path='/services' element={<Contact/>} />
      <Route path='/contact' element={<Contacttwo/>}/>
      <Route path='*' element={<Error/>} />
     </Routes>
      <Whatsapp/>
    
   
    </div>
  );
}

export default App;
