
import { Link } from 'react-router-dom';
import { MdOutlineEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";

import logo from './printlogo.png'
function Footer() {
  return (
    <div className='footer'>
      <div><img src={logo} alt="logo"  width={200}/></div>
      
         <p className='borderline'></p>
         <p style={{color:'white'}}><FaLocationDot /> : 69 Apata Street, Somolu, Lagos.</p>
         <p style={{color:'white'}}><Link to='tel:08038207295"' className='footerlogolink'><FaPhone />:08038207295</Link></p>
         <h3  style={{color:'black'}}><Link to='mailto:https://www.zoho.com/mail/admin@office-admin.ng'  className='link'  style={{color:'white'}}><MdOutlineEmail />:info@prints247.ng</Link></h3>
         
    </div>
  )
}

export default Footer;