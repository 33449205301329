import React from 'react'
import { Link } from 'react-router-dom';
import logo from './printlogo.png'
import { FaBarsStaggered } from "react-icons/fa6";
import { useState, useEffect } from 'react';
import Responsivenavbar from './Responsivenavbar';



function Navbar() {
  const [navstate, changenavstate] = useState(false)
  function nav(){
    changenavstate(!navstate)
  }
  const [ menuopen, setmenuopen]= useState(false)
  const [sticky,setsticky] = useState(false)
  useEffect(()=>{
    function handlescroll(){
      setsticky(window.scrollY > 150)
    }
    window.addEventListener('scroll', handlescroll)
    return()=> window.removeEventListener('scroll', handlescroll);
  })
  return (
    
    <nav className={`${sticky ? 'sticky' : ''}`}>
      <Link to='/' ><img src={logo} width={170} alt='print247logo' className='logo'/></Link>
      
      
      <div className='bars' ><FaBarsStaggered  /></div>
     
        <div className='navlinks'>
         
       
       <h3><Link to='/studio' className='link'>Studio</Link></h3>
     <h3><Link to='/about' className='link'>Stores</Link></h3>
     <h3><Link to='/services' className='link'>Services</Link></h3>
     <h3><Link to='/contact' className='link'>Contact</Link></h3>
  
     </div>
    
    
     
     
    </nav>
    
  )
  
}


export default Navbar;