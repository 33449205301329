import React from 'react'
import qrcode from './qrcodeprint247WhatsAppBusiness.jpg'


import Footer from '../two/Footer';

function Contact() {
  return (
    <div>
        <h1>link to our instagram <a className='qr' href="https://www.instagram.com/print247now/">click here</a></h1>
        <h1 className='qr'>Scan this qrcode to contact us </h1>
    <img src={qrcode} alt=""  style={{width:'40%', height:'10%'} }  data-aos="zoom-in-up" data-aos-duration="3000"/>
  
    <Footer/>
    </div>
  )
}

export default Contact;